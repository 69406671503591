
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import { WandIcon } from "vue-tabler-icons";
  import { apiLogin } from "../api";
  import { namespace } from "vuex-class";

  const store = namespace("auth");

  @Component({
    components: { LzButton, WandIcon }
  })
  export default class Signin extends Vue {
    signinForm = {
      email: "",
      password: ""
    };
    loadingPostLogin = false;
    errorMessage: string | null = null;

    @store.Action
    public updateData!: (payload: any) => void;

    @store.Action
    public updateFeatures!: (payload: any) => void;

    onSigninSubmit() {
      this.loadingPostLogin = true;
      this.errorMessage = null;

      apiLogin({
        email: this.signinForm.email,
        password: this.signinForm.password
      })
        .then(async (response: any) => {
          const { token, data } = response;
          if (token) {
            localStorage.setItem("accessToken", token);
            localStorage.setItem("auth", JSON.stringify(data));
            await this.updateData(data);
            await this.updateFeatures(data.id);
            this.$router.push({ name: "Home" });
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 400) {
            this.errorMessage = this.$tc("auth.signin.errors.wrongCredentials");
          } else {
            this.errorMessage = this.$tc("common.error.generic");
          }
          this.$notify({
            type: "error",
            text: this.errorMessage
          });
        })
        .finally(() => {
          this.loadingPostLogin = false;
        });
    }
  }
